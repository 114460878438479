<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- id -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" :disabled="true" />
        <!-- name -->
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" label="company" :error="error.companyId" :disabled="isEdit || disabled" />
        <!-- type -->
        <n-field-enum v-model="model.type" field-name="type" enum-name="TransactionType" :error="error.type" label="type" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- parentId -->
        <n-field-selection v-model="model.parentId" field-name="parentId" selection-name="company.category" label="parent" :error="error.parentId" :disabled="disabled" />
        <!-- icon -->
        <n-field-selection v-model="model.icon" field-name="icon" selection-name="market.icon" :error="error.icon" label="icon" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <!-- i18nKey -->
        <n-field-text v-model="model.i18nKey" field-name="i18nKey" label="i18nKey" :error="error.i18nKey" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, edit, add } from '@/api/company/company-category';

export default NModal.extend({
  name: 'ModalCompanyLeverage',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
