import { $fetch, $post, $put } from '@/services/axios';

export const CSV_URL = `company/categories/csv`;

export function fetch(data) {
  return $fetch(`company/categories`, data);
}

export function validate(data) {
  return $post(`company/category/validate`, data);
}

export function add(data) {
  return $put(`company/category`, data);
}

export function edit(data) {
  return $post(`company/category/${data.id}`, data);
}

export function sort(data) {
  return $post(`company/categories/sort`, data);
}
